import { HashRouter as Router, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import Fact from './components/Fact/Fact'
import Login from './components/Login/Login'
import Reportes from './components/Reportes/reportes'
import ReportesFact from './components/ReportesFact/reportesfact'
import ReportesComision from './components/ReportesComision/ReportesComision'
import ReportesColaborador from './components/ReportesColaborador/ReportesColaborador'

function App() {
  return (
    <Router>
      <div className="dashboard-container">
        {/* Render dashboard menu only if not on root route */}
        { window.location.hash !== "" && window.location.hash !== "/#/login" && window.location.hash !== "#/" && window.location.hash !== "#/login" &&(
          <div className="dashboard-menu">
            <nav className="dashboard-nav">
              <ul>
                <li><Link to="/fact">Subir Información</Link></li>
              </ul>
              <ul>
                <li><Link to="/reportescolaborador">Reporte Por Colaborador</Link></li>
              </ul>
              <ul>
                <li><Link to="/reportescomision">Reporte Comision</Link></li>
              </ul>
            </nav>
          </div>
        )}
        <div className="dashboard-content">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/fact" element={<Fact />} />
            {/* <Route path="/reportes" element={<Reportes />} /> */}
            {/* <Route path="/reportesfact" element={<ReportesFact />} /> */}
            <Route path="/reportescomision" element={<ReportesComision />} />
            <Route path="/reportescolaborador" element={<ReportesColaborador />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
export default App;