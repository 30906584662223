import React, { useState, useEffect } from 'react';

import axios from 'axios';
import './ReportesColaborador.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function ReportesColaborador() {
  const [facturas, setFacturas] = useState([]);

  const [filtroComision, setFiltroComision] = useState('');
  const [filtroPeriodicidad, setFiltroPeriodicidad] = useState('');
  const [filtroQueRemunera, setFiltroQueRemunera] = useState('');
  const [filtroCliente, setFiltroCliente] = useState('');
  
  const [filtroNombrePersona, setFiltroNombrePersona] = useState('');
  const [filtroRFC, setFiltroRFC] = useState('');
  const [filtroCURP, setFiltroCURP] = useState('');
  const [filtroBanco, setFiltroBanco] = useState('');
  const [filtroCLABE, setFiltroCLABE] = useState('');
  const [filtroTarjeta, setFiltroTarjeta] = useState('');
  const [filtroEsquema, setFiltroEsquema] = useState('');
  const [filtrPagadora, setFiltroPagadora] = useState('');
  const [filtroFechaSubidaInicio, setFiltroFechaSubidaInicio] = useState('');
  const [filtroFechaSubidaFin, setFiltroFechaSubidaFin] = useState('');
  const [filtroFechaPeriodoInicio, setFiltroFechaPeriodoInicio] = useState('');
  const [filtroFechaPeriodoFin, setFiltroFechaPeriodoFin] = useState('');

  const [facturaModif, setFacturaModif] = useState('');
  const [folioModif, setFolioModif] = useState('');
  const [fechaModif, setFechaModif] = useState('');
  const [open, setOpen] = useState(false);


  useEffect(() => {

    // e.preventDefault();
    
       let user= localStorage.getItem('claveID');
        let ob={user:user}


       const url = 'https://apifact.abecode.com/apifact/buscarId';
       const config = {
         method: 'post',
         headers: {
           'Content-Type': 'application/json'
         }
       };
       axios.post(url, ob, config)
         .then((response) => {
   
           if (response.data.mensaje=="existe"){
            console.log("bienvenido")
            
           } else{
            window.location.replace("/#/login");
            
           }
           console.log(response.data)
         })
         .catch((error) => {
        
           console.error(error);
         });

    //   const data = await revisarUsuario();
     
    

    
  }, []);

  // function handleOpen(factura) {
  // const fechaTexto = factura.fecha_pago;
  // console.log(factura.fecha_pago)
  // const fecha = new Date(fechaTexto);
  // fecha.setDate(fecha.getDate() + 1);
  // console.log(fecha)
  // const anio = fecha.getFullYear();
  // const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
  // const dia = fecha.getDate();
  // console.log(anio)
  // console.log(mes)
  // console.log(dia)
  // const fechaFormateada = `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
  // console.log(fechaFormateada)
  // setFechaModif(fechaFormateada)



  //   setFolioModif(factura.foliopago)
  //   setFacturaModif(factura)
  //   console.log((new Date(factura.fecha_pago)).toISOString())
  //   setOpen(true)
  // }

  function transformarFecha(fechaTexto){

    const fecha = new Date(fechaTexto);
    fecha.setDate(fecha.getDate() + 1);
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1; 
    const dia = fecha.getDate();
    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${anio}`;

    return fechaFormateada

  }

  
  function transformarFechaSubida(fechaTexto){
    // console.log(fecha)
    const fecha = new Date(fechaTexto);
    fecha.setDate(fecha.getDate());
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
    const dia = fecha.getDate();
    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${anio}`;
    // console.log(fecha)
    // const anio = fecha.getFullYear();
    // const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
    // const dia = fecha.getDate();
    // console.log(anio)
    // console.log(mes)
    // console.log(dia)
    // const fechaFormateada = `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
    return fechaFormateada

  }

  // function handleClose(factura) {
  //   setFacturaModif({})
  //   // setFechaModif("")
  //   // setFolioModif("")
  //   console.log(factura)
  //   setOpen(false)

  // }



  // function handleGuardar() {
  //   let facturaNueva = facturaModif
  //   console.log(facturaModif)
  //   facturaNueva.fecha_pago = fechaModif
  //   facturaNueva.foliopago = folioModif
  //   facturaNueva.pagado = "Si"

  //   const sendComision = (facturaNueva) => {
  //     // console.log(data)

  //     const url = 'https://apifact.abecode.com/apifact/modifComision';
  //     const config = {
  //       method: 'post',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     };
  //     axios.post(url, facturaNueva, config)
  //       .then((response) => {
  //         console.log(response)
  //       })
  //       .catch((error) => {
  //         alert("No se puede agregar: Falta Codigo-Nombre")
  //         console.error(error);
  //       });
  //   };
  //   sendComision(facturaNueva)
  //   // sendComision(facturaNueva)
  //   console.log(facturaNueva)
  //   setOpen(false)
  // }

  const handleExport = () => {
    /* Creamos el objeto workbook */
    const wb = XLSX.utils.table_to_book(document.querySelector('.table-class'));

    /* Convertimos el objeto workbook en un archivo Excel */
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    /* Descargamos el archivo */
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'facturas.xlsx');
  }



  useEffect(() => {
    async function loadFacturas() {
      const data = await getFacturasFromServer();
      setFacturas(data);
    }

    loadFacturas();
  }, []);

  // function handleAsignarPago(factura) {
  //   console.log(factura)
  // }

  return (
    <div>


      <div className="filters-container">
        <div className="filter-group">
          <label htmlFor="filter-invoice">Comision:</label>
          <input
            type="text"
            id="filter-invoice"
            value={filtroComision}
            onChange={(e) => setFiltroComision(e.target.value)}
            placeholder="Filtrar por factura"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-invoice">Periodicidad:</label>
          <input
            type="text"
            id="filter-invoice"
            value={filtroPeriodicidad}
            onChange={(e) => setFiltroPeriodicidad(e.target.value)}
            placeholder="Filtrar por clave Sat"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="filter-invoice">Que Remunera:</label>
          <input
            type="text"
            id="filter-invoice"
            value={filtroQueRemunera}
            onChange={(e) => setFiltroQueRemunera(e.target.value)}
            placeholder="Filtrar por Concepto"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="filter-client">Cliente:</label>
          <input
            type="text"
            id="filter-client"
            value={filtroCliente}
            onChange={(e) => setFiltroCliente(e.target.value)}
            placeholder="Filtrar por Cliente"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-company">Nombre Persona:</label>
          <input
            type="text"
            id="filter-company"
            value={filtroNombrePersona}
            onChange={(e) => setFiltroNombrePersona(e.target.value)}
            placeholder="Filtrar por Empresa"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-promotor1">RFC:</label>
          <input
            type="text"
            id="filter-promotor1"
            value={filtroRFC}
            onChange={(e) => setFiltroRFC(e.target.value)}
            placeholder="Filtrar por Promotor1"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-promotor2">CURP:</label>
          <input
            type="text"
            id="filter-promotor2"
            value={filtroCURP}
            onChange={(e) => setFiltroCURP(e.target.value)}
            placeholder="Filtrar por Promotor2"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-file-name">Banco:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroBanco}
            onChange={(e) => setFiltroBanco(e.target.value)}
            placeholder="Filtrar por Nombre de Archivo"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-file-name">CLABE:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroCLABE}
            onChange={(e) => setFiltroCLABE(e.target.value)}
            placeholder="Filtrar por Pagado"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-file-name">Tarjeta:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroTarjeta}
            onChange={(e) => setFiltroTarjeta(e.target.value)}
            placeholder="Filtrar por Pagado"
          />
        </div>

        
        <div className="filter-group">
          <label htmlFor="filter-file-name">Esquema:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroEsquema}
            onChange={(e) => setFiltroEsquema(e.target.value)}
            placeholder="Filtrar por Pagado"
          />
        </div>

        
        <div className="filter-group">
          <label htmlFor="filter-file-name">Pagadora :</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtrPagadora}
            onChange={(e) => setFiltroPagadora(e.target.value)}
            placeholder="Filtrar por Pagado"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-date">Fecha Captura:</label>
          <div className="date-filter-container">
            <label htmlFor="filter-date-start">Inicio:</label>
            <input
              type="date"
              id="filter-date-start"
              value={filtroFechaSubidaInicio}
              onChange={(e) => setFiltroFechaSubidaInicio(e.target.value)}
            />

            <label htmlFor="filter-date-end">Fin:</label>
            <input
              type="date"
              id="filter-date-end"
              value={filtroFechaSubidaFin}
              onChange={(e) => setFiltroFechaSubidaFin(e.target.value)}
            />
          </div>
        </div>

        {/* <div className="filter-group">
          <label htmlFor="filter-date">Fecha Periodo:</label>
          <div className="date-filter-container">
            <label htmlFor="filter-date-start">Inicio:</label>
            <input
              type="date"
              id="filter-date-start"
              value={filtroFechaPeriodoInicio}
              onChange={(e) => setFiltroFechaPeriodoInicio(e.target.value)}
            />

            <label htmlFor="filter-date-end">Fin:</label>
            <input
              type="date"
              id="filter-date-end"
              value={filtroFechaPeriodoFin}
              onChange={(e) => setFiltroFechaPeriodoFin(e.target.value)}
            />
          </div>
        </div> */}
      </div>

      <Button onClick={handleExport} variant="contained" color="success">
            Exportar a Excel
          </Button>

      <table className="table-class">
        <thead>
          <tr>
            <th>Fecha Subida </th>
            <th>Periodo Del</th>
            <th>Período Al</th>
            <th>Clave Interna</th>
            <th>Comisión </th>
            <th>Periodicidad</th>
            <th>Que Remunera</th>
            <th>Cliente</th>
            <th>Nombre de la Persona</th>
            <th>RFC</th>
            <th>CURP</th>
            <th>Total a Remunerar</th>
            <th>Banco</th>
            <th>Cuenta</th>
            <th>CLABE</th>
            <th>Tarjeta</th>
            <th>Esquema</th>
            <th>Pagadora</th>
            <th>Fecha Dispersión</th>
            <th>Nombre Archivo</th>
            

          </tr>
        </thead>
        <tbody>
          {facturas.filter((factura) => 
          factura.periodicidad.toLowerCase().includes(filtroPeriodicidad.toLowerCase()) &&
          factura.que_remunera.toLowerCase().includes(filtroQueRemunera.toLowerCase()) &&
          factura.cliente.toLowerCase().includes(filtroCliente.toLowerCase()) &&
          factura.nombre.toLowerCase().includes(filtroNombrePersona.toLowerCase()) &&
          factura.rfc.toLowerCase().includes(filtroRFC.toLowerCase())&&
          factura.curp.toLowerCase().includes(filtroCURP.toLowerCase()) &&
          factura.banco.toLowerCase().includes(filtroBanco.toLowerCase())&&
          factura.clabe.toLowerCase().includes(filtroCLABE.toLowerCase()) &&
          factura.tarjeta.toLowerCase().includes(filtroTarjeta.toLowerCase()) &&
          factura.esquema.toLowerCase().includes(filtroEsquema.toLowerCase()) &&
          factura.pagadora.toLowerCase().includes(filtrPagadora.toLowerCase()) &&
          (!filtroFechaSubidaInicio || factura.fecha >= filtroFechaSubidaInicio) && (!filtroFechaSubidaFin || factura.fecha <= filtroFechaSubidaFin  ))
          // (!filtroFechaPeriodoInicio || factura.fecha_del >= filtroFechaPeriodoInicio) && (!filtroFechaPeriodoFin || factura.fecha_del <= filtroFechaPeriodoFin  ) &&
          // (!filtroFechaPeriodoInicio || factura.fecha_al >= filtroFechaPeriodoInicio) && (!filtroFechaPeriodoFin || factura.fecha_al <= filtroFechaPeriodoFin  )) 
          .map((factura) => (
              <tr key={factura.factura}>
                <td>{transformarFechaSubida(factura.fecha)}</td>
                <td>{transformarFecha(factura.periodo_del)}</td>
                <td>{transformarFecha(factura.periodo_al)}</td>
                <td>{factura.clave_interna}</td>
                <td>{factura.comision}</td>
                <td>{factura.periodicidad}</td>
                <td>{factura.que_remunera}</td>
                <td>{factura.cliente}</td>
                <td>{factura.nombre}</td>
                <td>{factura.rfc}</td>
                <td>{factura.curp}</td>
                <td>{factura.remunerar}</td>
                <td>{factura.banco}</td>
                <td>{factura.cuenta}</td>
                <td>{factura.clabe}</td>
                <td>{factura.tarjeta}</td>
                <td>{factura.esquema}</td>
                
                <td>{factura.pagadora}</td>
                <td>{transformarFecha(factura.fecha_dispersion)}</td>
                <td>{factura.nombre_archivo}</td>

               
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

async function getFacturasFromServer() {
  const response = await axios.get('https://apifact.abecode.com/apifact/instruccionesAll');
  return response.data;
}
