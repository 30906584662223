
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fact from './Fact copy'
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Fact.css';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}






export default function FullWidthTabs() {
  const [otherSheets, setOtherSheets] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [totalSum, setTotalSum] = useState(0);
  const [totalSumConsilia, setSumaConsilia] = useState(0);
  const [sumaUltima, setSumaUltima] = useState(0);
  const [firstSheet, setFirstSheet] = useState({});
  const [lastSheet, setLastSheet] = useState({});
  const [datosPrimera, setDatosPrimera] = useState([]);
  const [datosUltima, setDatosUltima] = useState([]);
  const [nombreArchivo, setNombreArchivo] = useState();
  const [datosComision, setDatosComision] = useState([]);

  useEffect(() => {

    // e.preventDefault();
    
       let user= localStorage.getItem('claveID');
        let ob={user:user}


       const url = 'https://apifact.abecode.com/apifact/buscarId';
       const config = {
         method: 'post',
         headers: {
           'Content-Type': 'application/json'
         }
       };
       axios.post(url, ob, config)
         .then((response) => {
   
           if (response.data.mensaje=="existe"){
            console.log("bienvenido")
            
           } else{
            window.location.replace("/login");
            
           }
           console.log(response.data)
         })
         .catch((error) => {
        
           console.error(error);
         });

    //   const data = await revisarUsuario();
     
    

    
  }, []);





  useEffect(() => {
    let facturas=Object.values(otherSheets)
    let cons=datosPrimera
    let folios
    let listadoComision=[]


    if (facturas.length > 0) {
      
      facturas.map(function(factura) {
        let nomina=(buscarFolio(factura.folio,cons).subtotal)*1
        let comision= buscarFolio(factura.folio,cons).subtotal*.07.toFixed(2)
        let subtotal=(buscarFolio(factura.folio,cons).total*1).toFixed(2)
        let iva= (buscarFolio(factura.folio,cons).total*.16).toFixed(2)
        let total=(buscarFolio(factura.folio,cons).total*1.16).toFixed(2)
        let empresa=buscarPromotor1(factura.nombre).empresa

        let comisionObj={
          factura: factura.folio,
          claveSat: factura.claveSat,
          concepto:factura.concepto,
          fecha: new Date(),
          cliente: factura.nombre,
          empresa_f: buscarFolio(factura.folio,cons).sociedad,
          nomina: (nomina*1).toFixed(2),
          comision: (comision*1).toFixed(2),
          costo_social: 0,
          subtotal: (subtotal*1).toFixed(2),
          iva: (iva*1).toFixed(2),
          total:(total*1).toFixed(2),
          comision_promotor1: empresa==1?nomina*0.05.toFixed(2):
                              empresa==2?nomina*0.06.toFixed(2):
                              empresa==3?(((comision*1+iva*1)-(total*.05))/3).toFixed(2):
                              empresa==4?((comision*1+iva*1-(subtotal*0.05))/3).toFixed(2):
                              empresa==5?(nomina*0.05).toFixed(2):
                              empresa==6?(nomina*0.05).toFixed(2):
                              empresa==7?((comision*1+iva*1-(total*.05))*.5).toFixed(2):
                              empresa==8?(nomina*0.01).toFixed(2):0,
          promotor1: buscarPromotor1(factura.nombre).promotor,
          comision_promotor2: empresa==1?(nomina*0.02+subtotal*.02).toFixed(2):
                              empresa==2?0:
                              empresa==3?(((comision*1+iva*1)-(total*.05))/3).toFixed(2):
                              empresa==4?0:
                              empresa==5?0:
                              empresa==6?(nomina*0.02+subtotal*.02).toFixed(2):
                              empresa==7?0:
                              empresa==8?0:0,
          promotor2: buscarPromotor2(factura.nombre).promotor,
          total_comision: empresa==1?((nomina*0.05)+(nomina*0.02+subtotal*.02)).toFixed(2):
                              empresa==2?(nomina*0.06).toFixed(2):
                              empresa==3?((((comision*1+iva*1)-(total*.05))/3)*2).toFixed(2):
                              empresa==4?((comision*1+iva*1-(subtotal*0.05))/3).toFixed(2):
                              empresa==5?(nomina*0.05).toFixed(2):
                              empresa==6?((nomina*0.05)+(nomina*0.02+subtotal*.02)).toFixed(2):
                              empresa==7?((comision*1+iva*1-(total*.05))*.5).toFixed(2):
                              empresa==8?(nomina*0.01).toFixed(2):0,
          nombre_archivo:nombreArchivo,
          pagado:"No",
          foliopago:"-"
        }
        listadoComision.push(comisionObj)
        



        
        console.log(listadoComision)
        revisarComision(listadoComision)
      });
      
    }
    


    // console.log(facturas);
    // console.log(cons);
  }, [otherSheets, datosPrimera]);


  function buscarFolio(folio,cons){
    let encontrado = {};
    
    for (let i = 0; i < cons.length; i++) {
      if (cons[i].folio === folio) {
        encontrado = cons[i];
        break;
      }
    }
    
    return encontrado;
  }

  function buscarComision1(nombre){
    
    return 1;
  }

  function buscarPromotor1(nombre){

    switch (nombre.toLowerCase().replace(/\s/g, '')) {
      case "TECNIBANDAS INDUSTRIALES SA DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"RMT", empresa:"1"});
      case "CENTRO INTERNACIONAL DE NEGOCIOS MONTERREY A.C.".toLowerCase().replace(/\s/g, ''):
      return({promotor:"A. TREVIÑO", empresa:2});
      case "CONSOLIDAMEX SA DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"JC. YACAMAN",empresa:3});
      case "INFRAESTRUCTURA LATINOAMERICANA SA DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"C. VALLEJO",empresa:4});
      case "NORDIC FERROUS TECHNOLOGIES SA DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"F. VILLEGAS",rmpesa:5});
      case "PRODUCTOS INDUSTRIALES DEL GOLFO SA DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"RMT",empresa:6});
      case "REACCIONES QUIMICAS SA DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"L. INZUNZA",empresa:7});
      case "SERVICIOS PROFESIONALES PARA EL TALENTO FEMENINO SAPI DE CV".toLowerCase().replace(/\s/g, ''):
      return({promotor:"A. PEREZ",empresa:8});
      default:
      return({promotor:"error1",empresa:0});
      }
  
  }

  function buscarComision2(nombre){
    
    return 1;
  }

  function buscarPromotor2(nombre){
    // console.log(nombre)
    
    if(nombre.toLowerCase().replace(/\s/g, '')=="TECNIBANDAS INDUSTRIALES SA DE CV".toLowerCase().replace(/\s/g, '')){
      return({promotor:"HCC",empresa:1})
    } else if(nombre.toLowerCase().replace(/\s/g, '')=="CENTRO INTERNACIONAL DE NEGOCIOS MONTERREY A.C.".toLowerCase().replace(/\s/g, '')){
      return({promotor:"-",empresa:2})
    }else if(nombre.toLowerCase().replace(/\s/g, '')=="CONSOLIDAMEX SA DE CV".toLowerCase().replace(/\s/g, '')){
      return(({promotor:"E.SOLIS",empresa:3}))
    }else if(nombre.toLowerCase().replace(/\s/g, '')=="INFRAESTRUCTURA LATINOAMERICANA SA DE CV".toLowerCase().replace(/\s/g, '')){
      return({promotor:"-",empresa:4})
    }else if(nombre.toLowerCase().replace(/\s/g, '')=="NORDIC FERROUS TECHNOLOGIES SA DE CV".toLowerCase().replace(/\s/g, '')){
      return({promotor:"-",empresa:5})
    }else if(nombre.toLowerCase().replace(/\s/g, '')=="PRODUCTOS INDUSTRIALES DEL GOLFO SA DE CV".toLowerCase().replace(/\s/g, '')){
      return(({promotor:"HCC",empresa:6}))
    }else if(nombre.toLowerCase().replace(/\s/g, '')=="REACCIONES QUIMICAS SA DE CV".toLowerCase().replace(/\s/g, '')){
      return({promotor:"-",empresa:7})
    }else if(nombre.toLowerCase().replace(/\s/g, '')==="SERVICIOS PROFESIONALES PARA EL TALENTO FEMENINO SAPI DE CV".toLowerCase().replace(/\s/g, '')){
      return({promotor:"-",empresa:8})
    }else return("error2")
  }

  const handleFileChange = (event) => {
    var a = totalSum;
    const file = event.target.files[0];
    const nombreArchivo2 = file.name
    setNombreArchivo(file.name);

    // Read the file and extract the data
    const reader = new FileReader();
    reader.onload = (event) => {
      setFirstSheet({})
      a = 0
      setTotalSum(0)
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const otherSheetData = {};

      ////////////////////////////////guardar los datos de la ULTIMA hoja////////////////////////////////
      const ultimahoja = workbook.SheetNames[workbook.SheetNames.length - 1];
      // console.log(ultimahoja)
      const sheet = workbook.Sheets[ultimahoja];
      setLastSheet(sheet)
      // console.log(sheet)
      let datosUltima = []
      let celdaLetraU = "A"
      let celdaNumeroU = "10"
      let c = 0
      while (sheet[celdaLetraU + celdaNumeroU].v) {
        let filaU = {
          fecha: startDate,
          nombre_archivo: nombreArchivo2,
          que_remunera: sheet['C1'] ? sheet['C1'].v : null,
          cliente: sheet['C2'] ? sheet['C2'].v : null,
          comision: sheet['C3'] ? sheet['C3'].v : null,
          periodicidad: sheet['C4'] ? sheet['C4'].v : null,
          periodo_del: sheet['C5'] ? new Date((sheet['C5'].v - 25569) * 86400 * 1000) : null,
          periodo_al: sheet['C6'] ? new Date((sheet['C6'].v - 25569) * 86400 * 1000) : null,
          no: sheet[celdaLetraU + celdaNumeroU].v,
          clave_interna: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 1)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 1)) + celdaNumeroU].v : "-",
          nombre: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 2)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 2)) + celdaNumeroU].v : "-",
          rfc: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 3)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 3)) + celdaNumeroU].v : "-",
          curp: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 4)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 4)) + celdaNumeroU].v : "-",
          remunerar: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 5)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 5)) + celdaNumeroU].v.toFixed(2) : "-",
          banco: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 6)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 6)) + celdaNumeroU].v : "-",
          cuenta: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 7)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 7)) + celdaNumeroU].v : "-",
          clabe: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 8)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 8)) + celdaNumeroU].v : "-",
          tarjeta: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 9)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 9)) + celdaNumeroU].v : "-",
          observaciones: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 10)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 10)) + celdaNumeroU].v : "-",
          pagadora: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 11)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 11)) + celdaNumeroU].v : "-",
          esquema: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 12)) + celdaNumeroU] ? sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 12)) + celdaNumeroU].v : "-",
          fecha_dispersion: sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 13)) + celdaNumeroU] ? new Date((sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 13)) + celdaNumeroU].v - 25569) * 86400 * 1000) : "01/01/1900",
          existe: false,
          nombre_archivo_no: nombreArchivo2 + "-" + sheet[celdaLetraU + celdaNumeroU].v
        }



        new Date((sheet[(String.fromCharCode(celdaLetraU.charCodeAt(0) + 13)) + celdaNumeroU].v - 25569) * 86400 * 1000);

        if (filaU.remunerar === 0 || filaU.remunerar == null) {
          // datosUltima.push(filaU);
          // console.log("nada")
        } else {
          datosUltima.push(filaU);
        }
        setDatosUltima(datosUltima)
        celdaNumeroU++;
        if (sheet[celdaLetraU + celdaNumeroU]) {
          // console.log("sigue");
        } else {
          break;
        }
        // celdaLetra = String.fromCharCode(celdaLetra.charCodeAt(0) + 1);
      }
      revisarCargaUltima(datosUltima)




      ////////////////////////////////////////////////////////////////////////////////////////////////////

      // Guarda la primera hoja en firstSheet
      setFirstSheet(workbook.Sheets[workbook.SheetNames[0]])
      // console.log("ESTA ES")
      // console.log(workbook.Sheets[workbook.SheetNames[0]])
      let worksheet = workbook.Sheets[workbook.SheetNames[0]]
      ////////////////////////////////guardar los datos de la primera hoja////////////////////////////////
      const datosPrimera = [];
      let celdaLetra = "B"
      let celdaNumero = "4"
      let b = 0
      // console.log(celdaLetra + celdaNumero)
      // console.log(worksheet[celdaLetra + celdaNumero].v)
      while (worksheet[celdaLetra + celdaNumero].v) {
        let fila = {
          fecha: startDate,
          nombre_archivo: nombreArchivo2,
          codigo: worksheet[celdaLetra + celdaNumero].v,
          des_especifica: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 1)) + celdaNumero].v,
          des_factura: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 2)) + celdaNumero].v,
          sociedad: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 3)) + celdaNumero].v,
          subtotal: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 4)) + celdaNumero] ? worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 4)) + celdaNumero].v.toFixed(2) : 0,
          total: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 5)) + celdaNumero] ? worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 5)) + celdaNumero].v.toFixed(2) : 0,
          a_pagar: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 6)) + celdaNumero] ? worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 6)) + celdaNumero].v.toFixed(2) : 0,
          folio: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 7)) + celdaNumero] ? worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 7)) + celdaNumero].v : 0,
          fecha_emision: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 8)) + celdaNumero] ? worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 8)) + celdaNumero].v : "01/01/1900",
          fecha_pago: worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 9)) + celdaNumero] ? worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 9)) + celdaNumero].v : "01/01/1900",
          existe: false,
          clave_nombre: nombreArchivo2.concat(worksheet[celdaLetra + celdaNumero].v)
        }
        if (fila.total == 0 || fila.total == null) {
          // console.log("nada")
        } else {
          b = b + worksheet[(String.fromCharCode(celdaLetra.charCodeAt(0) + 6)) + celdaNumero].v
          setSumaConsilia(b.toFixed(2))
          // console.log(fila.total)
          datosPrimera.push(fila);
        }

        // console.log(datosPrimera)
        setDatosPrimera(datosPrimera)
        celdaNumero++;
        if (worksheet[celdaLetra + celdaNumero]) {
          // console.log("sigue");
        } else {
          break;
        }
        // celdaLetra = String.fromCharCode(celdaLetra.charCodeAt(0) + 1);
      }
      revisarCargaPrimera(datosPrimera)





      ///////////////////////////////////////////////////////////////////////////////////////

      workbook.SheetNames.slice(1, -1).forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        otherSheetData[sheetName] = {
          fecha: startDate,
          folio: worksheet['F7'] ? worksheet['F7'].v : null,
          facturador: worksheet['B5'] ? worksheet['B5'].v : null,
          nombre: worksheet['C9'] ? worksheet['C9'].v : null,
          domicilio: worksheet['C10'] ? worksheet['C10'].v : null,
          colonia: worksheet['C11'] ? worksheet['C11'].v : null,
          ciudad: worksheet['C12'] ? worksheet['C12'].v : null,
          cp: worksheet['C13'] ? worksheet['C13'].v : null,
          estado: worksheet['C14'] ? worksheet['C14'].v : null,
          claveSat: worksheet['C16'] ? worksheet['C16'].v : null,
          unidadMedida: worksheet['D16'] ? worksheet['D16'].v : null,
          concepto: worksheet['E16'] ? worksheet['E16'].v : null,
          rfc: worksheet['F13'] ? worksheet['F13'].v : null,
          precioUnitario: worksheet['F16'] ? worksheet['F16'].v : null,
          importe: worksheet['G16'] ? worksheet['G16'].v : null,
          subtotal: worksheet['G33'] ? worksheet['G33'].v : null,
          iva: worksheet['G35'] ? worksheet['G35'].v : null,
          total: worksheet['G37'] ? worksheet['G37'].v : null,
          nombre_archivo: nombreArchivo2,
          existe: false
        };
        a = a + worksheet['G37'].v
        setTotalSum(a.toFixed(2))
      });

      revisarCarga(otherSheetData)



    };
    console.log(otherSheets)
    console.log(datosPrimera)
    console.log(datosUltima)

    reader.readAsBinaryString(file);
  };




  // ENVIA LA INFORMACIÓN PARA SER AGREGADA A LA BASE DE DATA 
  const sendData = (data) => {
    // Define the URL and request config
    const url = 'https://apifact.abecode.com/apifact/agregarFactura';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, data, config)
      .then((response) => {
        // console.log(response);
        revisarCarga(otherSheets)
      })
      .catch((error) => {
        alert("No se puede agregar: Falta FOLIO")
        console.error(error);
      });
  };

  const sendDataPrimera = (data) => {
    // console.log(data)

    const url = 'https://apifact.abecode.com/apifact/agregarConsilia';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, data, config)
      .then((response) => {
        // console.log(response);
        revisarCargaPrimera(datosPrimera)
      })
      .catch((error) => {
        alert("No se puede agregar: Falta Codigo-Nombre")
        console.error(error);
      });
  };


  const sendDataUltima = (data) => {
    // console.log(data)

    const url = 'https://apifact.abecode.com/apifact/agregarInstruccion';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, data, config)
      .then((response) => {
        // console.log(response);
        revisarCargaUltima(datosUltima)
      })
      .catch((error) => {
        alert("No se puede agregar: Falta Codigo-Nombre")
        console.error(error);
      });
  };

  const sendComision = (data) => {
    // console.log(data)

    const url = 'https://apifact.abecode.com/apifact/agregarComision';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, data, config)
      .then((response) => {
        // console.log(response);
        revisarComision(datosComision)
      })
      .catch((error) => {
        alert("No se puede agregar: Falta Codigo-Nombre")
        console.error(error);
      });
  };


  // REVISA LA CARGA DEL EXCEL PARA VERIFICAR CUALES YA EXISTEN EN LA BASE DE DATOS Y REGRESA LA INFORMACIÓN IDENTIFICADA 
  const revisarCarga = (data) => {
    // Define the URL and request config
    const url = 'https://apifact.abecode.com/apifact/revisarCarga';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, data, config)
      .then((response) => {
        setOtherSheets(response.data);
        // console.log(response);
        return response.data
      })
      .catch((error) => {
        console.error(error);
        return error
      });
  };


  const revisarCargaPrimera = (data) => {
    // console.log("CARGA PRIMERA")
    // console.log(data)
    // Define the URL and request config
    const url = 'https://apifact.abecode.com/apifact/revisarCargaPrimera';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, data, config)
      .then((response) => {
        setDatosPrimera(response.data);
        // console.log(response.data)
        return response.data
      })
      .catch((error) => {
        console.error(error);
        return error
      });
  };

  const revisarComision = (data) => {
    // console.log("CARGA PRIMERA")
    // console.log(data)
    // Define the URL and request config
    const url = 'https://apifact.abecode.com/apifact/revisarComision';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, data, config)
      .then((response) => {
        setDatosComision(response.data);
        console.log(response.data)
        // console.log(response.data)
        return response.data
      })
      .catch((error) => {
        console.error(error);
        return error
      });
  };

  const revisarCargaUltima = (data) => {
    // console.log("CARGA PRIMERA")
    // console.log(data)
    // Define the URL and request config
    const url = 'https://apifact.abecode.com/apifact/revisarCargaUltima';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, data, config)
      .then((response) => {
        setDatosUltima(response.data);
        // console.log("ESTE ES EL REPONSE DATA")
        // console.log(response.data)
        return response.data
      })
      .catch((error) => {
        console.error(error);
        return error
      });
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Subir" {...a11yProps(0)} />
          <Tab label="Facturas" {...a11yProps(1)} />
          <Tab label="Consiliacion" {...a11yProps(2)} />
          <Tab label="Emitido" {...a11yProps(3)} />
          <Tab label="Comision" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <input type="file" accept=".xlsx,.xls" onChange={handleFileChange} />

        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <table className="table-class">
            <thead>
              <tr>
                <th>Folio</th>
                <th>Facturador</th>
                <th>Nombre</th>
                <th>Domicilio</th>
                <th>Colonia</th>
                <th>Ciudad</th>
                <th>CP</th>
                <th>Estado</th>
                <th>Clave SAT</th>
                <th>Unidad de medida</th>
                <th>Concepto</th>
                <th>RFC</th>
                <th>Precio unitario</th>
                <th>Importe</th>
                <th>Subtotal</th>
                <th>IVA</th>
                <th>Total</th>
                <th>Agregar</th>
                <th>Actualizar</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(otherSheets).map((sheetName) => (
                <tr key={sheetName}>
                  <td>{otherSheets[sheetName].folio}</td>
                  <td>{otherSheets[sheetName].facturador}</td>
                  <td>{otherSheets[sheetName].nombre}</td>
                  <td>{otherSheets[sheetName].domicilio}</td>
                  <td>{otherSheets[sheetName].colonia}</td>
                  <td>{otherSheets[sheetName].ciudad}</td>
                  <td>{otherSheets[sheetName].cp}</td>
                  <td>{otherSheets[sheetName].estado}</td>
                  <td>{otherSheets[sheetName].claveSat}</td>
                  <td>{otherSheets[sheetName].unidadMedida}</td>
                  <td>{otherSheets[sheetName].concepto}</td>
                  <td>{otherSheets[sheetName].rfc}</td>
                  <td>{otherSheets[sheetName].precioUnitario.toFixed(2)}</td>
                  <td>{otherSheets[sheetName].importe.toFixed(2)}</td>
                  <td>{otherSheets[sheetName].subtotal.toFixed(2)}</td>
                  <td>{otherSheets[sheetName].iva.toFixed(2)}</td>
                  <td>{otherSheets[sheetName].total.toFixed(2)}</td>
                  <td>

                    {otherSheets[sheetName].folio == null ? <button disabled>Falta Folio</button> : otherSheets[sheetName].existe ?
                      <button disabled>Ya Existe Folio</button>
                      : <button disabled={otherSheets[sheetName].existe} onClick={() => sendData(otherSheets[sheetName])}>Enviar</button>
                    }

                    {/* <button onClick={() => revisar(otherSheets[sheetName])}>Revisar</button> */}

                  </td>
                  <td>

                    <button onClick={() => sendData(otherSheets[sheetName])}>Actualizar</button>
             

                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL</td>
                <td>{totalSum}</td>
              </tr>
            </tfoot>
          </table>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <table className="table-class">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Factura</th>
                <th>Descripción Específica</th>
                <th>Descripción Factura</th>
                <th>Sociedad</th>
                <th>SubTotal</th>
                <th>Total</th>
                <th>A Pagar </th>
                <th>Fecha Emision </th>
                <th>Fecha Pago </th>
                <th>Subir </th>
                <th>Actualizar </th>

              </tr>
            </thead>
            <tbody>
              {datosPrimera.map((objeto, index) => (
                <tr key={index}>
                  <td>{objeto.codigo}</td>
                  <td>{objeto.folio}</td>
                  <td>{objeto.des_especifica}</td>
                  <td>{objeto.des_factura}</td>
                  <td>{objeto.sociedad}</td>
                  <td>{objeto.subtotal}</td>
                  <td>{objeto.total}</td>
                  <td>{objeto.a_pagar}</td>
                  <td>{objeto.fecha_emision == "01/01/1900" ? "Sin Fecha" : objeto.fecha_emision}</td>
                  <td>{objeto.fecha_pago == "01/01/1900" ? "Sin Fecha" : objeto.fecha_pago}</td>

                  <td>

                    {objeto.clave_nombre == null ? <button disabled>Error</button> : objeto.existe ?
                      <button disabled>Ya Existe Folio</button>
                      : <button disabled={objeto.existe} onClick={() => sendDataPrimera(objeto)}>Enviar</button>
                    }

                    {/* <button onClick={() => revisar(otherSheets[sheetName])}>Revisar</button> */}

                  </td>
                  <td><button onClick={() => sendDataPrimera(objeto)}>Actualizar</button></td>


                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL</td>
                <td>{totalSumConsilia}</td>
              </tr>
            </tfoot>
          </table>
        </TabPanel>
      
        <TabPanel value={value} index={3} dir={theme.direction}>
        <table className="table-class">
            <thead>
              <tr>
                <th>Que Remunera</th>
                <th>Cliente</th>
                <th>Comision</th>
                <th>Periodicidad</th>
                <th>Clave Interna</th>
                <th>Nombre</th>
                <th>RFC</th>
                <th>CURP </th>
                <th>Total Remunerar </th>
                <th>Banco </th>
                <th>Cuenta </th>
                <th>Clabe </th>
                <th>Tarjeta </th>
                <th>Observaciones </th>
                <th>Pagadora </th>
                <th>Esquema </th>
                <th>Fecha Dispersion </th>
                <th>Agregar </th>
                <th>Actualizar </th>

              </tr>
            </thead>
            <tbody>
              {datosUltima.map((objeto, index) => (
                <tr key={index}>
                  <td>{objeto.que_remunera}</td>
                  <td>{objeto.cliente}</td>
                  <td>{objeto.comision}</td>
                  <td>{objeto.periodicidad}</td>
                  <td>{objeto.clave_interna}</td>
                  <td>{objeto.nombre}</td>
                  <td>{objeto.rfc}</td>
                  <td>{objeto.curp}</td>
                  <td>{objeto.remunerar}</td>
                  <td>{objeto.banco}</td>
                  <td>{objeto.cuenta}</td>
                  <td>{objeto.clabe}</td>
                  <td>{objeto.tarjeta}</td>
                  <td>{objeto.observaciones}</td>
                  <td>{objeto.pagadora}</td>
                  <td>{objeto.esquema}</td>
                  <td>{objeto.fecha_dispersion}</td>

                  <td>

                    {objeto.nombre_archivo_no == null ? <button disabled>Error</button> : objeto.existe ?
                      <button disabled>Ya Existe Folio</button>
                      : <button disabled={objeto.existe} onClick={() => sendDataUltima(objeto)}>Enviar</button>
                    }



                  </td>
                  <td> <button onClick={() => sendDataUltima(objeto)}>Actualizar</button></td>
                </tr>
              ))}
            </tbody>
            <tfoot>

            </tfoot>
          </table>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
        <table className="table-class">
            <thead>
              <tr>
                <th>Factura</th>

                <th>ClaveSat</th>
                <th>Concepto</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Emmpresa</th>
                <th>Nomina</th>
                <th>Comision</th>
                <th>Costo Social </th>
                <th>Subtotal</th>
                <th>Iva </th>
                <th>Total </th>
                <th>Comision Promotor 1 </th>
                <th>Promotor 1 </th>
                <th>Comision Promotor 2 </th>
                <th>Promotor 2 </th>
                <th>Total Comision </th>
                <th>Pagado </th>
                <th>Folio Pago </th>

              </tr>
            </thead>
            <tbody>
              {datosComision.map((objeto, index) => (
                <tr key={index}>
                  <td>{objeto.factura}</td>
                  <td>{objeto.claveSat}</td>
                  <td>{objeto.concepto}</td>
                  <td>{objeto.fecha}</td>
                  <td>{objeto.cliente}</td>
                  <td>{objeto.empresa_f}</td>
                  <td>{objeto.nomina}</td>
                  <td>{objeto.comision}</td>
                  <td>{objeto.costo_social}</td>
                  <td>{objeto.subtotal}</td>
                  <td>{objeto.iva}</td>
                  <td>{objeto.total}</td>
                  <td>{objeto.comision_promotor1}</td>
                  <td>{objeto.promotor1}</td>
                  <td>{objeto.comision_promotor2}</td>
                  <td>{objeto.promotor2}</td>
                  <td>{objeto.total_comision}</td>
                  <td>{objeto.pagado}</td>
                  <td>{objeto.foliopago}</td>

                  <td>

                    {objeto.existe == null ? <button disabled>Error</button> : objeto.existe ?
                      <button disabled>Ya Existe Folio</button>
                      : <button disabled={objeto.existe} onClick={() => sendComision(objeto)}>Enviar</button>
                    }



                  </td>
                  <td> <button onClick={() => sendComision(objeto)}>Actualizar</button></td>

                </tr>
              ))}
            </tbody>
            <tfoot>

            </tfoot>
          </table>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}