import React, { useState, useEffect } from 'react';
import './Login.css';
import axios from 'axios';







function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  useEffect(() => {

    // e.preventDefault();
    
       let user= localStorage.getItem('claveID');
        let ob={user:user}


       const url = 'https://apifact.abecode.com/apifact/buscarId';
       const config = {
         method: 'post',
         headers: {
           'Content-Type': 'application/json'
         }
       };
       axios.post(url, ob, config)
         .then((response) => {
   
           if (response.data.mensaje=="existe"){
            
            window.location.replace("/#/fact");
           } else{
               alert("Usuario o contraseña Incorrectas")
           }
           console.log(response.data)
         })
         .catch((error) => {
        
           console.error(error);
         });

    //   const data = await revisarUsuario();
     
    

    
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Username: ${username}, Password: ${password}`);
    // Aquí puedes agregar la lógica para enviar los datos del formulario al servidor
  };

  function submit(){
      let usuario=username
      let pass=password
      let info={usuario:usuario, pass:pass}

    const url = 'https://apifact.abecode.com/apifact/buscarUsuarios';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, info, config)
      .then((response) => {

        if (response.data.mensaje=="existe"){

            localStorage.setItem('claveID', response.data.id);
            window.location.replace("/#/fact");
        } else{
            localStorage.setItem('claveID', "");
            alert("Usuario o contraseña Incorrectas")
        }
        console.log(response.data)
      })
      .catch((error) => {
        
        console.error(error);
      });

      console.log(username)
      console.log(password)
  }

  return (
    <div className="login-wrapper">
      <h2>Iniciar sesión</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Usuario:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" onClick={submit}>Iniciar sesión</button>
      </form>
    </div>
  );
}

export default Login;
