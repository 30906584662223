import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, Table } from 'react-bootstrap';

function Fact() {
  const [otherSheets, setOtherSheets] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [totalSum, setTotalSum] = useState(0);
  const [firstSheet, setFirstSheet] = useState({});
  const handleFileChange = (event) => {
    var a=totalSum;
    const file = event.target.files[0];

    // Read the file and extract the data
    const reader = new FileReader();
    reader.onload = (event) => {
      setFirstSheet({})
      a=0
      setTotalSum(0)
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const otherSheetData = {};
      setFirstSheet(workbook.Sheets[workbook.SheetNames[0]])
      workbook.SheetNames.slice(1, -1).forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        otherSheetData[sheetName] = {
          fecha: startDate,
          folio: worksheet['F7'] ? worksheet['F7'].v : null,
          facturador: worksheet['B5'] ? worksheet['B5'].v : null,
          nombre: worksheet['C9'] ? worksheet['C9'].v : null,
          domicilio: worksheet['C10'] ? worksheet['C10'].v : null,
          colonia: worksheet['C11'] ? worksheet['C11'].v : null,
          ciudad: worksheet['C12'] ? worksheet['C12'].v : null,
          cp: worksheet['C13'] ? worksheet['C13'].v : null,
          estado: worksheet['C14'] ? worksheet['C14'].v : null,
          claveSat: worksheet['C16'] ? worksheet['C16'].v : null,
          unidadMedida: worksheet['D16'] ? worksheet['D16'].v : null,
          concepto: worksheet['E16'] ? worksheet['E16'].v : null,
          rfc: worksheet['F13'] ? worksheet['F13'].v : null,
          precioUnitario: worksheet['F16'] ? worksheet['F16'].v : null,
          importe: worksheet['G16'] ? worksheet['G16'].v : null,
          subtotal: worksheet['G33'] ? worksheet['G33'].v : null,
          iva: worksheet['G35'] ? worksheet['G35'].v : null,
          total: worksheet['G37'] ? worksheet['G37'].v : null,
          existe: false
        };
        a=a+worksheet['G37'].v
        setTotalSum(a.toFixed(2))
      });

      revisarCarga(otherSheetData)

      // sendData(otherSheetData);
    };
    reader.readAsBinaryString(file);
  };

  // REVISA LA CARGA DEL EXCEL PARA VERIFICAR CUALES YA EXISTEN EN LA BASE


  // ENVIA LA INFORMACIÓN PARA SER AGREGADA A LA BASE DE DATA 
  const sendData = (data) => {
    // Define the URL and request config
    const url = 'http://localhost:4000/agregarFactura';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, data, config)
      .then((response) => {
        console.log(response);
        revisarCarga(otherSheets)
      })
      .catch((error) => {
        alert("No se puede agregar: Falta FOLIO")
        console.error(error);
      });
  };

  // REVISA SI EXISTE EL REGISTRO EN LA BASE DE DATOA 
  const revisar = (data) => {
    // Define the URL and request config
    const url = 'http://localhost:4000/revisar';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(url, data, config)
      .then((response) => {
        console.log(response);
        return response
      })
      .catch((error) => {
        console.error(error);
        return (error)
      });
  };

  // REVISA LA CARGA DEL EXCEL PARA VERIFICAR CUALES YA EXISTEN EN LA BASE DE DATOS Y REGRESA LA INFORMACIÓN IDENTIFICADA 
  const revisarCarga = (data) => {
    // Define the URL and request config
    const url = 'http://localhost:4000/revisarCarga';
    const config = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.post(url, data, config)
      .then((response) => {
        setOtherSheets(response.data);
        console.log(response);
        return response.data
      })
      .catch((error) => {
        console.error(error);
        return error
      });
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
      />
      <input type="file" accept=".xlsx,.xls" onChange={handleFileChange} />



  <table>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Facturador</th>
            <th>Nombre</th>
            <th>Domicilio</th>
            <th>Colonia</th>
            <th>Ciudad</th>
            <th>CP</th>
            <th>Estado</th>
            <th>Clave SAT</th>
            <th>Unidad de medida</th>
            <th>Concepto</th>
            <th>RFC</th>
            <th>Precio unitario</th>
            <th>Importe</th>
            <th>Subtotal</th>
            <th>IVA</th>
            <th>Total</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(otherSheets).map((sheetName) => (
            <tr key={sheetName}>
              <td>{otherSheets[sheetName].folio}</td>
              <td>{otherSheets[sheetName].facturador}</td>
              <td>{otherSheets[sheetName].nombre}</td>
              <td>{otherSheets[sheetName].domicilio}</td>
              <td>{otherSheets[sheetName].colonia}</td>
              <td>{otherSheets[sheetName].ciudad}</td>
              <td>{otherSheets[sheetName].cp}</td>
              <td>{otherSheets[sheetName].estado}</td>
              <td>{otherSheets[sheetName].claveSat}</td>
              <td>{otherSheets[sheetName].unidadMedida}</td>
              <td>{otherSheets[sheetName].concepto}</td>
              <td>{otherSheets[sheetName].rfc}</td>
              <td>{otherSheets[sheetName].precioUnitario.toFixed(2)}</td>
              <td>{otherSheets[sheetName].importe.toFixed(2)}</td>
              <td>{otherSheets[sheetName].subtotal.toFixed(2)}</td>
              <td>{otherSheets[sheetName].iva.toFixed(2)}</td>
              <td>{otherSheets[sheetName].total.toFixed(2)}</td>
              <td>

                {otherSheets[sheetName].folio == null ? <button disabled>Falta Folio</button> : otherSheets[sheetName].existe ?
                  <button disabled>Ya Existe Folio</button>
                  : <button disabled={otherSheets[sheetName].existe} onClick={() => sendData(otherSheets[sheetName])}>Enviar</button>
                }

                {/* <button onClick={() => revisar(otherSheets[sheetName])}>Revisar</button> */}

              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
      <tr>
        <td>TOTAL</td>
        <td>{totalSum}</td>
      </tr>
    </tfoot>
      </table>
 
     

      {/* {firstSheet ? <Consilia firstSheetData={firstSheet} /> : "0"} */}
      <div>
    {/* {renderTable()} */}
  </div>
    </div>
  );
}


export default Fact;

