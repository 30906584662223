import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ReportesComision.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function ReportesComision() {
  const [facturas, setFacturas] = useState([]);
  const [filtroFactura, setFiltroFactura] = useState('');
  const [filtroEmpresa, setFiltroEmpresa] = useState('');
  const [filtroCliente, setFiltroCliente] = useState('');
  const [filtroPromotor1, setFiltroPromotor1] = useState('');
  const [filtroPromotor2, setFiltroPromotor2] = useState('');
  const [filtroNombreArchivo, setFiltroNombreArchivo] = useState('');
  const [filtroFechaInicio, setFiltroFechaInicio] = useState('');
  const [filtroFechaFin, setFiltroFechaFin] = useState('');
  const [filtroClaveSat, setFiltClaveSat] = useState('');
  const [filtroConcepto, setFiltroConcepto] = useState('');
  const [filtroPagado, setFiltroPagado] = useState('');
  const [filtroFolio, setFiltroFolio] = useState('');
  const [filtroFechaPagoInicio, setFiltroFechaPagoInicio] = useState('');
  const [filtroFechaPagoFin, setFiltroFechaPagoFin] = useState('');
  const [facturaModif, setFacturaModif] = useState('');
  const [folioModif, setFolioModif] = useState('');
  const [fechaModif, setFechaModif] = useState('');
  const [open, setOpen] = useState(false);




  function handleOpen(factura) {
  const fechaTexto = factura.fecha_pago;
  console.log(factura.fecha_pago)
  const fecha = new Date(fechaTexto);
  fecha.setDate(fecha.getDate() + 1);
  console.log(fecha)
  const anio = fecha.getFullYear();
  const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
  const dia = fecha.getDate();
  console.log(anio)
  console.log(mes)
  console.log(dia)
  const fechaFormateada = `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
  console.log(fechaFormateada)
  setFechaModif(fechaFormateada)



    setFolioModif(factura.foliopago)
    setFacturaModif(factura)
    console.log((new Date(factura.fecha_pago)).toISOString())
    setOpen(true)
  }

  function transformarFecha(fechaTexto){
    // console.log(fecha)
    const fecha = new Date(fechaTexto);
    fecha.setDate(fecha.getDate() + 1);
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
    const dia = fecha.getDate();
    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${anio}`;
    // console.log(fecha)
    // const anio = fecha.getFullYear();
    // const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
    // const dia = fecha.getDate();
    // console.log(anio)
    // console.log(mes)
    // console.log(dia)
    // const fechaFormateada = `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
    return fechaFormateada

  }

  useEffect(() => {

    // e.preventDefault();
    
       let user= localStorage.getItem('claveID');
        let ob={user:user}


       const url = 'https://apifact.abecode.com/apifact/buscarId';
       const config = {
         method: 'post',
         headers: {
           'Content-Type': 'application/json'
         }
       };
       axios.post(url, ob, config)
         .then((response) => {
   
           if (response.data.mensaje=="existe"){
            console.log("bienvenido")
            
           } else{
            window.location.replace("/#/login");
            
           }
           console.log(response.data)
         })
         .catch((error) => {
        
           console.error(error);
         });

    //   const data = await revisarUsuario();
     
    

    
  }, []);
  
  function transformarFechaSubida(fechaTexto){
    // console.log(fecha)
    const fecha = new Date(fechaTexto);
    fecha.setDate(fecha.getDate());
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
    const dia = fecha.getDate();
    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${anio}`;
    // console.log(fecha)
    // const anio = fecha.getFullYear();
    // const mes = fecha.getMonth() + 1; // Agregar 1 porque los meses se indexan desde 0
    // const dia = fecha.getDate();
    // console.log(anio)
    // console.log(mes)
    // console.log(dia)
    // const fechaFormateada = `${anio}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
    return fechaFormateada

  }

  function handleClose(factura) {
    setFacturaModif({})
    // setFechaModif("")
    // setFolioModif("")
    console.log(factura)
    setOpen(false)

  }



  function handleGuardar() {
    let facturaNueva = facturaModif
    console.log(facturaModif)
    facturaNueva.fecha_pago = fechaModif
    facturaNueva.foliopago = folioModif
    facturaNueva.pagado = "Si"

    const sendComision = (facturaNueva) => {
      // console.log(data)

      const url = 'https://apifact.abecode.com/apifact/modifComision';
      const config = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      };
      axios.post(url, facturaNueva, config)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          alert("No se puede agregar: Falta Codigo-Nombre")
          console.error(error);
        });
    };
    sendComision(facturaNueva)
    // sendComision(facturaNueva)
    console.log(facturaNueva)
    setOpen(false)
  }

  const handleExport = () => {
    /* Creamos el objeto workbook */
    const wb = XLSX.utils.table_to_book(document.querySelector('.table-class'));

    /* Convertimos el objeto workbook en un archivo Excel */
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    /* Descargamos el archivo */
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'facturas.xlsx');
  }



  useEffect(() => {
    async function loadFacturas() {
      const data = await getFacturasFromServer();
      setFacturas(data);
    }

    loadFacturas();
  }, []);

  function handleAsignarPago(factura) {
    console.log(factura)
  }

  return (
    <div>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Factura:{facturaModif.factura}
          </Typography>
          <div className="filter-group">
            <label htmlFor="filter-invoice">Folio:</label>
            <input
              type="text"
              id="filter-invoice"
              value={folioModif}
              onChange={(e) => setFolioModif(e.target.value)}
              placeholder="Filtrar por factura"
            />

            
         <div className="date-filter-container">
              <div className="filtergroup-">
                <label htmlFor="filter-date-start">Fecha de Pago:</label>
                <input
                  type="date"
                  id="filter-date-start"
                  value={fechaModif}
                  onChange={(e) => setFechaModif(e.target.value)}
                /></div></div>



          </div>

          <Button onClick={handleGuardar} variant="contained" color="success">
            Guardar
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cerrar
          </Button>
          {/* <button onClick={() => handleGuardar()}>Guardar</button>
          <button onClick={() => handleClose()}>Cancelar</button> */}

        </Box>
      </Modal>


      <div className="filters-container">
        <div className="filter-group">
          <label htmlFor="filter-invoice">Factura:</label>
          <input
            type="text"
            id="filter-invoice"
            value={filtroFactura}
            onChange={(e) => setFiltroFactura(e.target.value)}
            placeholder="Filtrar por factura"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-invoice">Clave Sat:</label>
          <input
            type="text"
            id="filter-invoice"
            value={filtroClaveSat}
            onChange={(e) => setFiltClaveSat(e.target.value)}
            placeholder="Filtrar por clave Sat"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="filter-invoice">Concepto:</label>
          <input
            type="text"
            id="filter-invoice"
            value={filtroConcepto}
            onChange={(e) => setFiltroConcepto(e.target.value)}
            placeholder="Filtrar por Concepto"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="filter-client">Cliente:</label>
          <input
            type="text"
            id="filter-client"
            value={filtroCliente}
            onChange={(e) => setFiltroCliente(e.target.value)}
            placeholder="Filtrar por Cliente"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-company">Empresa:</label>
          <input
            type="text"
            id="filter-company"
            value={filtroEmpresa}
            onChange={(e) => setFiltroEmpresa(e.target.value)}
            placeholder="Filtrar por Empresa"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-promotor1">Promotor1:</label>
          <input
            type="text"
            id="filter-promotor1"
            value={filtroPromotor1}
            onChange={(e) => setFiltroPromotor1(e.target.value)}
            placeholder="Filtrar por Promotor1"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-promotor2">Promotor2:</label>
          <input
            type="text"
            id="filter-promotor2"
            value={filtroPromotor2}
            onChange={(e) => setFiltroPromotor2(e.target.value)}
            placeholder="Filtrar por Promotor2"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-file-name">Nombre de Archivo:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroNombreArchivo}
            onChange={(e) => setFiltroNombreArchivo(e.target.value)}
            placeholder="Filtrar por Nombre de Archivo"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-file-name">Pagado:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroPagado}
            onChange={(e) => setFiltroPagado(e.target.value)}
            placeholder="Filtrar por Pagado"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-file-name">Folio Pago:</label>
          <input
            type="text"
            id="filter-file-name"
            value={filtroFolio}
            onChange={(e) => setFiltroFolio(e.target.value)}
            placeholder="Filtrar por Pagado"
          />
        </div>

        <div className="filter-group">
          <label htmlFor="filter-date">Fecha Captura:</label>
          <div className="date-filter-container">
            <label htmlFor="filter-date-start">Inicio:</label>
            <input
              type="date"
              id="filter-date-start"
              value={filtroFechaInicio}
              onChange={(e) => setFiltroFechaInicio(e.target.value)}
            />

            <label htmlFor="filter-date-end">Fin:</label>
            <input
              type="date"
              id="filter-date-end"
              value={filtroFechaFin}
              onChange={(e) => setFiltroFechaFin(e.target.value)}
            />
          </div>
        </div>

        <div className="filter-group">
          <label htmlFor="filter-date">Fecha Pago:</label>
          <div className="date-filter-container">
            <label htmlFor="filter-date-start">Inicio:</label>
            <input
              type="date"
              id="filter-date-start"
              value={filtroFechaPagoInicio}
              onChange={(e) => setFiltroFechaPagoInicio(e.target.value)}
            />

            <label htmlFor="filter-date-end">Fin:</label>
            <input
              type="date"
              id="filter-date-end"
              value={filtroFechaPagoFin}
              onChange={(e) => setFiltroFechaPagoFin(e.target.value)}
            />
          </div>
        </div>
      </div>

      <Button onClick={handleExport} variant="contained" color="success">
            Exportar a Excel
          </Button>

      <table className="table-class">
        <thead>
          <tr>
            <th>Factura </th>
            <th>Clave Sat</th>
            <th>Concepto</th>
            <th>Fecha Subida</th>
            <th>Cliente</th>
            <th>Empresa_f</th>
            <th>Nomina</th>
            <th>Comision</th>
            <th>Costo_social</th>
            <th>Subtotal</th>
            <th>IVA</th>
            <th>Total</th>
            <th>Comision_promotor1</th>
            <th>Promotor1</th>
            <th>Comision_promotor2</th>
            <th>Promotor2</th>
            <th>Total_comision</th>
            <th>Nombre_archivo</th>
            <th>Pagado</th>
            <th>Foliopago</th>
            <th>Fecha Pago</th>

          </tr>
        </thead>
        <tbody>
          {facturas
            .filter((factura) => factura.foliopago.toLowerCase().includes(filtroFolio.toLowerCase()) && factura.pagado.toLowerCase().includes(filtroPagado.toLowerCase()) &&factura.concepto.toLowerCase().includes(filtroConcepto.toLowerCase()) && factura.claveSat.toLowerCase().includes(filtroClaveSat.toLowerCase()) && factura.factura.toLowerCase().includes(filtroFactura.toLowerCase()) && factura.empresa_f.toLowerCase().includes(filtroEmpresa.toLowerCase())
              && factura.cliente.toLowerCase().includes(filtroCliente.toLowerCase()) && factura.promotor1.toLowerCase().includes(filtroPromotor1.toLowerCase())
              && factura.promotor2.toLowerCase().includes(filtroPromotor2.toLowerCase()) && factura.nombre_archivo.toLowerCase().includes(filtroNombreArchivo.toLowerCase())
              && (!filtroFechaInicio || factura.fecha >= filtroFechaInicio) && (!filtroFechaFin || factura.fecha <= filtroFechaFin  ) 
              && (!filtroFechaPagoInicio || factura.fecha_pago >= filtroFechaPagoInicio) &&
              (!filtroFechaPagoFin || factura.fecha_pago <= filtroFechaPagoFin))
            .map((factura) => (
              <tr key={factura.factura}>
                <td>{factura.factura}</td>
                <td>{factura.claveSat}</td>
                <td>{factura.concepto}</td>
                <td>{transformarFechaSubida(factura.fecha)}</td>
                <td>{factura.cliente}</td>
                <td>{factura.empresa_f}</td>
                <td>{factura.nomina}</td>
                <td>{factura.comision}</td>
                <td>{factura.costo_social}</td>
                <td>{factura.subtotal}</td>
                <td>{factura.iva}</td>
                <td>{factura.total}</td>
                <td>{factura.comision_promotor1}</td>
                <td>{factura.promotor1}</td>
                <td>{factura.comision_promotor2}</td>
                <td>{factura.promotor2}</td>
                <td>{factura.total_comision}</td>
                <td>{factura.nombre_archivo}</td>
                <td>
                  {factura.pagado}
                  <button onClick={() => handleOpen(factura)}>Asignar</button>
                </td>
                <td>{factura.foliopago}</td>
                <td>{factura.fecha_pago == "1900-01-01T06:36:36.000Z" ? "-" : transformarFecha(factura.fecha_pago)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

async function getFacturasFromServer() {
  const response = await axios.get('https://apifact.abecode.com/apifact/comisionesAll');
  return response.data;
}
